// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("/opt/build/repo/node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-how-it-works-js": () => import("/opt/build/repo/src/templates/how_it_works.js" /* webpackChunkName: "component---src-templates-how-it-works-js" */),
  "component---src-templates-landing-page-js": () => import("/opt/build/repo/src/templates/landing_page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-components-localized-redirect-js": () => import("/opt/build/repo/src/components/localized_redirect.js" /* webpackChunkName: "component---src-components-localized-redirect-js" */),
  "component---src-templates-home-js": () => import("/opt/build/repo/src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-shop-directory-js": () => import("/opt/build/repo/src/templates/shop_directory.js" /* webpackChunkName: "component---src-templates-shop-directory-js" */),
  "component---src-templates-our-merchants-js": () => import("/opt/build/repo/src/templates/our_merchants.js" /* webpackChunkName: "component---src-templates-our-merchants-js" */),
  "component---src-templates-partners-js": () => import("/opt/build/repo/src/templates/partners.js" /* webpackChunkName: "component---src-templates-partners-js" */),
  "component---src-templates-info-page-js": () => import("/opt/build/repo/src/templates/info_page.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-email-start-js": () => import("/opt/build/repo/src/pages/email-start.js" /* webpackChunkName: "component---src-pages-email-start-js" */)
}

